body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    color: white;
    background-color: #282c34;
}

select {
    background-color: #282c34;
    color: white;
}

a {
    color: #aa99ff;
}

a:active {
    color: white;
}


@media (orientation: portrait) {
    html {
        transform: rotate(90deg);
    }
}