.player {
    width: 100vmax;
    height: 100vmin;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 5fr 7fr 5fr 7fr 5fr;
    grid-auto-flow: column;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
            column-gap: 1px;
}

.player__button {
    box-sizing: border-box;
    border-radius: 2vmin;
    background-color: #ddd;
    display: inline-block;
    color: black;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 5vmin;
}

.player__button:not(.player__button--top) {
    border-top: solid black 1px;
}

.player__button--left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.player__button--right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.player__button--highlight {
    background-color: #ffffff;
}

.player__button--active {
    color: white;
    background-color: #aa99ff;
}

.player__button--active.player__button--altProfile {
    color: #666;
    background-color: #ffd599;
}

.player__button--disabled {
    cursor: not-allowed;
    color: #666;
    opacity: 0.6;
}

.player__key {
    grid-column: 3;
    grid-row: 1;
    text-align: center;
    font-weight: bold;
}

.player__config {
    grid-column: 3;
    grid-row: 2;
}

.player__tilt {
    grid-column: 3;
    grid-row-start: 3;
    grid-row-end: 6;
}

.player__config,
.player__tilt {
    display: flex;
    justify-content: center;
    align-items: center;
}


.player__tilt {
    flex-direction: column;
    text-align: center;
}

.player__back > svg,
.player__config > svg {
    margin-right: 0.25em;
}

.player__tilt svg {
    color: #ffd599;
}

.player--altProfile .player__tilt svg {
    color: #aa99ff;
}

.player__spacer {
    flex-grow: 1;
}

.site {
    width: 100vmax;
    min-height: 100vmin;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.site__logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.site__header {
    font-weight: 450;
}

.site__help {
    text-align: left;
    padding: 0 1em;
}

.site__help li + li {
    margin-top: 0.5em;
}

.site__title {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    margin-top: 0;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    font-size: 2em;
}

.site__header p {
    margin: 0;
}

.site__links {
    display: flex;
    justify-content: space-around;
    width: 26em;
    margin: 0.5em 0;
}

.site__link {
    display: flex;
    align-items: center;
}

.site__link > svg {
    margin-right: 0.25em;
}

.site__options {
    margin-top: 0.5em;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.5em;
    grid-column-gap: 2em;
    grid-auto-flow: column;
}

.site__option {
    display: flex;
}

.site__label {
    text-align: left;
    margin-right: 0.5em;
}

.site__value {
    flex-grow: 1;
}

.site__useAlt {
    margin-top: 1em;
}

.site__options--alternate {
    margin-top: 1em;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    color: white;
    background-color: #282c34;
}

select {
    background-color: #282c34;
    color: white;
}

a {
    color: #aa99ff;
}

a:active {
    color: white;
}


@media (orientation: portrait) {
    html {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}
