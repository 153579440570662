.player {
    width: 100vmax;
    height: 100vmin;
    user-select: none;

    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 5fr 7fr 5fr 7fr 5fr;
    grid-auto-flow: column;
    column-gap: 1px;
}

.player__button {
    box-sizing: border-box;
    border-radius: 2vmin;
    background-color: #ddd;
    display: inline-block;
    color: black;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 5vmin;
}

.player__button:not(.player__button--top) {
    border-top: solid black 1px;
}

.player__button--left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.player__button--right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.player__button--highlight {
    background-color: #ffffff;
}

.player__button--active {
    color: white;
    background-color: #aa99ff;
}

.player__button--active.player__button--altProfile {
    color: #666;
    background-color: #ffd599;
}

.player__button--disabled {
    cursor: not-allowed;
    color: #666;
    opacity: 0.6;
}

.player__key {
    grid-column: 3;
    grid-row: 1;
    text-align: center;
    font-weight: bold;
}

.player__config {
    grid-column: 3;
    grid-row: 2;
}

.player__tilt {
    grid-column: 3;
    grid-row-start: 3;
    grid-row-end: 6;
}

.player__config,
.player__tilt {
    display: flex;
    justify-content: center;
    align-items: center;
}


.player__tilt {
    flex-direction: column;
    text-align: center;
}

.player__back > svg,
.player__config > svg {
    margin-right: 0.25em;
}

.player__tilt svg {
    color: #ffd599;
}

.player--altProfile .player__tilt svg {
    color: #aa99ff;
}

.player__spacer {
    flex-grow: 1;
}
