.site {
    width: 100vmax;
    min-height: 100vmin;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.site__logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.site__header {
    font-weight: 450;
}

.site__help {
    text-align: left;
    padding: 0 1em;
}

.site__help li + li {
    margin-top: 0.5em;
}

.site__title {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    margin-top: 0;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    font-size: 2em;
}

.site__header p {
    margin: 0;
}

.site__links {
    display: flex;
    justify-content: space-around;
    width: 26em;
    margin: 0.5em 0;
}

.site__link {
    display: flex;
    align-items: center;
}

.site__link > svg {
    margin-right: 0.25em;
}

.site__options {
    margin-top: 0.5em;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.5em;
    grid-column-gap: 2em;
    grid-auto-flow: column;
}

.site__option {
    display: flex;
}

.site__label {
    text-align: left;
    margin-right: 0.5em;
}

.site__value {
    flex-grow: 1;
}

.site__useAlt {
    margin-top: 1em;
}

.site__options--alternate {
    margin-top: 1em;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}
